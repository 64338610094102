// import React from 'react'
// import axios from 'axios';
// import { useForm } from "react-hook-form";

// export function Register() {


//     const { register  , handleSubmit } = useForm()


//     function Submit (data) { 
//         console.log(data);
//         axios.post('https://apna.org.pk/apna.org.pk/api/register', data).then(() => {}).catch(() => {});

//     }
//     return (<>
//         <>
//             <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto mt-10 ">
//                 <div className="rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-800 bg-[#f5f5f5]">
//                     <div className="mb-8">
//                         <h2 className="text-xl font-bold text-blue-600 dark:text-neutral-200">
//                             Profile
//                         </h2>
//                         <p className="text-sm text-gray-800 dark:text-neutral-400">
//                             Manage your name, password and account settings.
//                         </p>
//                     </div>
//                     <form onSubmit={handleSubmit(Submit)} >

//                         <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

//                             <div className="sm:col-span-3 text-blue-600">
//                                 <label
//                                     htmlFor="af-account-full-name"
//                                     className="inline-block text-sm text-blue-6000 mt-2.5 dark:text-neutral-200"
//                                 >
//                                     Full name
//                                 </label>
//                                 <div className="hs-tooltip inline-block ">
//                                     <button type="button" className="hs-tooltip-toggle ms-1">
//                                         <svg
//                                             className="inline-block size-3 text-gray-400 dark:text-neutral-600"
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             width={16}
//                                             height={16}
//                                             fill="currentColor"
//                                             viewBox="0 0 16 16"
//                                         >
//                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
//                                             <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
//                                         </svg>
//                                     </button>
//                                     <span
//                                         className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
//                                         role="tooltip"
//                                     >
//                                         Displayed on public forums, such as Preline
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="sm:flex">
//                                     <input
//                                         id="af-account-full-name"
//                                         type="text"
//                                         name='First Name'
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         placeholder="Saqib"
//                                         required
//                                         {...register('firstname')}
//                                     />
//                                     <input
//                                         type="text"
//                                         name='Second Name'
//                                         className="  py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 "
//                                         placeholder="Ayaz"
//                                         required
//                                         {...register('secondname')}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-3 ">
//                                 <label
//                                     htmlFor="af-account-email"
//                                     className="inline-block text-sm text-blue-600  mt-2.5 dark:text-neutral-200"
//                                 >
//                                     Email
//                                 </label>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <input
//                                     id="af-account-email"
//                                     name='email'
//                                     type="email"
//                                     className=" py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500  dark:focus:ring-neutral-600"
//                                     placeholder="saqib@site.com"
//                                     required
//                                     {...register('email')}
//                                 />
//                             </div>
//                             <div className="sm:col-span-3">
//                                 <label
//                                     htmlFor="af-account-password"
//                                     className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
//                                 >
//                                     Password
//                                 </label>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="space-y-2">
//                                     <input
//                                         id="af-account-password"
//                                         type="text"
//                                         name='email'
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 "
//                                         placeholder="password"
//                                         required
//                                         {...register('password')}
//                                     />

//                                 </div>
//                             </div>
//                             <div className="sm:col-span-3">
//                                 <div className="inline-block">
//                                     <label
//                                         htmlFor="af-account-phone"
//                                         className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
//                                     >
//                                         Phone
//                                     </label>
//                                     <span className="text-sm text-gray-800 dark:text-neutral-600">
//                                         (Optional)
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="sm:flex">
//                                     <input
//                                         id="af-account-phone"
//                                         name='phone'
//                                         type="text"
//                                         className=" py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 "
//                                         placeholder="+x(xxx)xxx-xx-xx"
//                                         required
//                                         {...register('phone')}
//                                     />
//                                     <select className=" py-2 px-3 pe-9 block w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500   dark:focus:ring-neutral-600">
//                                         <option selected="">Mobile</option>
//                                         <option>Home</option>
//                                         <option>Work</option>
//                                         <option>Fax</option>
//                                     </select>
//                                 </div>

//                             </div>

//                             <div className="sm:col-span-3">
//                                 <label
//                                     htmlFor="af-account-bio"
//                                     className="inline-block text-sm  text-blue-600 mt-2.5 dark:text-neutral-200"
//                                 >
//                                     BIO
//                                 </label>
//                             </div>
//                             <div className="sm:col-span-9 ">
//                                 <textarea
//                                     id="af-account-bio"
//                                     name='bio'
//                                     className=" py-2 px-3 block w-full border-blue-600 rounded-lg text-sm focus:border-blue-600 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800  dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-blue-600"
//                                     rows={6}
//                                     placeholder="Type your message..."
//                                     defaultValue={""}
//                                     required
//                                     {...register('bio')}
//                                 />
//                             </div>
//                         </div>
//                         <div className="mt-5 flex justify-end gap-x-2">



//                          <a
//                             href=""
//                             className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
//                         >
//                             Register
//                         </a>
//                         </div>
//                     </form>
//                 </div>
//                 {/* End Card */}
//             </div>
//             {/* End Card Section */}
//         </>

//     </>
//     )
// }
















// import axios from 'axios';
// import React from 'react';
// import { useForm } from 'react-hook-form';

// export function Register() {
//     const { register, handleSubmit } = useForm();

//     function Submit(data) {
//         console.log(data);
//         axios.post('https://apna.org.pk/apna.org.pk/api/register', data)
//             .then(() => {})
//             .catch(() => {});
//     }

//     return (
//         <>
//             <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto mt-10">
//                 <div className="rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-800 bg-[#f5f5f5]">
//                     <div className="mb-8">
//                         <h2 className="text-xl font-bold text-blue-600 dark:text-neutral-200">
//                             Profile
//                         </h2>
//                         <p className="text-sm text-gray-800 dark:text-neutral-400">
//                             Manage your name, password and account settings.
//                         </p>
//                     </div>
//                     <form onSubmit={handleSubmit(Submit)}>
//                         <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
//                             <div className="sm:col-span-3 text-blue-600">
//                                 <label htmlFor="af-account-full-name" className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200">
//                                     Full name
//                                 </label>
//                                 <div className="hs-tooltip inline-block">
//                                     <button type="button" className="hs-tooltip-toggle ms-1">
//                                         <svg className="inline-block size-3 text-gray-400 dark:text-neutral-600" xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" viewBox="0 0 16 16">
//                                             <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
//                                             <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
//                                         </svg>
//                                     </button>
//                                     <span className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible w-40 text-center z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700" role="tooltip">
//                                         Displayed on public forums, such as Preline
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="sm:flex">
//                                     <input
//                                         id="af-account-full-name"
//                                         type="text"
//                                         name="firstName"
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         placeholder="Saqib"
//                                         required
//                                         {...register('firstName')}
//                                     />
//                                     <input
//                                         type="text"
//                                         name="secondName"
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         placeholder="Ayaz"
//                                         required
//                                         {...register('secondName')}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-3">
//                                 <label htmlFor="af-account-email" className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200">
//                                     Email
//                                 </label>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <input
//                                     id="af-account-email"
//                                     name="email"
//                                     type="email"
//                                     className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                     placeholder="saqib@site.com"
//                                     required
//                                     {...register('email')}
//                                 />
//                             </div>
//                             <div className="sm:col-span-3">
//                                 <label htmlFor="af-account-password" className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200">
//                                     Password
//                                 </label>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="space-y-2">
//                                     <input
//                                         id="af-account-password"
//                                         type="password"
//                                         name="password"
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         placeholder="password"
//                                         required
//                                         {...register('password')}
//                                     />
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-3">
//                                 <div className="inline-block">
//                                     <label htmlFor="af-account-phone" className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200">
//                                         Phone
//                                     </label>
//                                     <span className="text-sm text-gray-800 dark:text-neutral-600">
//                                         (Optional)
//                                     </span>
//                                 </div>
//                             </div>
//                             <div className="sm:col-span-9">
//                                 <div className="sm:flex">
//                                     <input
//                                         id="af-account-phone"
//                                         name="phone"
//                                         type="text"
//                                         className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         placeholder="+x(xxx)xxx-xx-xx"
//                                         required
//                                         {...register('phone')}
//                                     />
//                                     <select className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
//                                         {...register('role')}
//                                     >
//                                         <option value="Student">Student</option>
//                                         <option value="Teacher">Teacher</option>
//                                         <option value="Professional">Professional</option>
//                                     </select>
//                                 </div>
//                             </div>
//                         </div>
//                         <button type="submit" className="mt-4 px-4 py-2 text-white bg-blue-600 rounded-lg">
//                             Register
//                         </button>
//                     </form>
//                 </div>
//             </div>
//         </>
//     );
// }


















import React from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";

export function Register() {
    const { register, handleSubmit, reset } = useForm();

    // function Submit(data) {
    //     console.log("Posting data...");

    //     const formData = new FormData();
  
    //     // Append other form data
    //     Object.keys(data).forEach(key => {
    //       formData.append(key, data[key]);
    //     });
      
    //     axios.post('https://apna.org.pk/apna.org.pk/api/register', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     })
    //     .then(() => {
    //       console.log("Data posted successfully");
    //     })
    //     .catch((error) => {
    //       console.error("Error posting data:", error);
    //     });
    // }


    // function Submit(data) {
    //     console.log("Posting data...");
    
    //     const formData = new FormData();
      
    //     // Append other form data
    //     Object.keys(data).forEach(key => {
    //       formData.append(key, data[key]);
    //     });
          
    //     axios.post('https://apna.org.pk/apna.org.pk/api/register', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //       },
    //     })
    //     .then(() => {
    //       console.log("Data posted successfully");
    //       reset(); // Reset form fields after successful submission
    //     })
    //     .catch((error) => {
    //       console.error("Error posting data:", error);
    //     });
    // }



    function Submit(data) {
        console.log("Posting data...");
    
        const formData = new FormData();
      
        // Append other form data
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
    
        // Log FormData content for debugging
        for (let pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }
        
        axios.post('https://apna.org.pk/apna.org.pk/api/register', data, {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //   },
        })
        .then(() => {
          console.log("Data posted successfully");
          reset(); // Reset form fields after successful submission
        })
        .catch((error) => {
          console.error("Error posting data:", error.response ? error.response.data : error.message);
        });
    }
    

    return (
        <>
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 mx-auto mt-10">
                <div className="rounded-xl shadow p-4 sm:p-7 dark:bg-neutral-800 bg-[#f5f5f5]">
                    <div className="mb-8">
                        <h2 className="text-xl font-bold text-blue-600 dark:text-neutral-200">
                            Profile
                        </h2>
                        <p className="text-sm text-gray-800 dark:text-neutral-400">
                            Manage your name, password and account settings.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(Submit)}>
                        <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">

                            <div className="sm:col-span-3">
                                <label className="inline-block text-sm text-gray-800 mt-2.5">
                                    Profile photo
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <div className="flex items-center gap-5">
                                    <img
                                        className="inline-block size-16 rounded-full ring-2 ring-white dark:ring-neutral-900"
                                        src="https://preline.co/assets/img/160x160/img1.jpg"
                                        alt="Avatar"
                                    />
                                    <div className="flex gap-x-2">
                                        <div>
                                            <input
                                                type="file"
                                                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                                                accept=".jpeg, .png, .jpg, .gif, .svg"
                                                {...register("file")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-3 text-blue-600">
                                <label
                                    htmlFor="af-account-full-name"
                                    className="inline-block text-sm text-blue-6000 mt-2.5 dark:text-neutral-200"
                                >
                                    Full name
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input
                                        id="af-account-full-name"
                                        name='first_name'
                                        type="text"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="Saqib"
                                        required
                                        {...register('first_name')}
                                    />
                                    <input
                                        type="text"
                                        name='last_name'
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="Ayaz"
                                        required
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="af-account-email"
                                    className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
                                >
                                    Email
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <input
                                    id="af-account-email"
                                    name='email'
                                    type="email"
                                    className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm text-sm rounded-lg focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                    placeholder="saqib@site.com"
                                    required
                                    {...register('email')}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="af-account-password"
                                    className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
                                >
                                    Password
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <div className="space-y-2">
                                    <input
                                        id="af-account-password"
                                        name='password'
                                        type="password"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="password"
                                        required
                                        {...register('password')}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="af-account-phone"
                                    className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
                                >
                                    Phone
                                </label>
                                <span className="text-sm text-gray-800 dark:text-neutral-600">
                                    (Optional)
                                </span>
                            </div>
                            <div className="sm:col-span-9">
                                <div className="sm:flex">
                                    <input
                                        id="af-account-phone"
                                        name='phone'
                                        type="text"
                                        className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                        placeholder="+x(xxx)xxx-xx-xx"
                                        required
                                        {...register('phone')}
                                    />
                                    <select className="py-2 px-3 pe-9 block w-full sm:w-auto border-gray-200 shadow-sm -mt-px -ms-px first:rounded-t-lg last:rounded-b-lg sm:first:rounded-s-lg sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-lg text-sm relative focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600">
                                        <option>Mobile</option>
                                        <option>Home</option>
                                        <option>Work</option>
                                        <option>Fax</option>
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="af-account-bio"
                                    className="inline-block text-sm text-blue-600 mt-2.5 dark:text-neutral-200"
                                >
                                    BIO
                                </label>
                            </div>
                            <div className="sm:col-span-9">
                                <textarea
                                    id="af-account-bio"
                                    name='bio'
                                    className="py-2 px-3 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-blue-600"
                                    rows={6}
                                    placeholder="Type your message..."
                                    required
                                    {...register('bio')}
                                />
                            </div>
                        </div>
                        <div className="mt-5 flex justify-end gap-x-2">
                            <button
                                type="submit"
                                className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}


