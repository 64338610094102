import React from 'react'
// import './b.css'
import { Link } from 'react-router-dom';
export function B() {

    return (<>
        {/* shadow-[0_4px_30px_#3bc3ef] */}

        <div className="container px-5 py-24 mx-auto bg-[#f5f5f5]">
            <div className="flex flex-col text-center w-full mb-20">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-blue-600">
                    All Pakistan Network Association
                </h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    In Pakistan, The All Pakistan Network Association (APNA) functions as a key organization that facilitates collaboration and cooperation among telecom companies operating under the Pakistan Telecom Authority (PTA) regulatory umbrella. APNA plays a crucial role in supporting industry standards, encouraging innovation, and ensuring compliance with regulatory frameworks. It serves as a unified voice for the telecom sector, network operators, and network equipment providers, promoting sustainable growth and advancing technological advancements to meet the evolving needs of consumers nationwide.
                </p>
            </div>
            <div className="flex flex-wrap justify-center">
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-[#3bc3ef] border-opacity-60"
                    style={{ borderLeft: '2px solid #3bc3ef' }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                        e.currentTarget.style.border = 'none';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                        e.currentTarget.style.borderLeft = '2px solid #3bc3ef';
                    }}
                >
                    <div className='flex justify-center'>
                        <img src="/media/svg-icon/icon-1.png" alt="" />
                    </div>
                    <h2 className="text-blue-600 tracking-widest text-center font-medium title-font mb-1 sm:text-1xl text-2xl title-font">
                        Free Membership
                    </h2>
                    <p className="leading-relaxed text-base mb-4 text-center">
                        We are excited to announce that we now offer free membership to all digital communication enthusiasts.
                    </p>
                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-[#3bc3ef] border-opacity-60"
                    style={{ borderLeft: '2px solid #3bc3ef' }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                        e.currentTarget.style.border = 'none';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                        e.currentTarget.style.borderLeft = '2px solid #3bc3ef';
                    }}
                >
                    <div className='flex justify-center'>
                        <img src="/media/svg-icon/icon-2.png" className="h-auto max-w-24" alt="" />
                    </div>
                    <h2 className="text-blue-600 mt-3 tracking-widest text-center font-medium title-font mb-1 sm:text-xl">
                        Complaints Resolved
                    </h2>
                    <p className="leading-relaxed text-base mb-4 text-center">
                        Policy advocacy shapes inclusive and innovative digital policies.
                    </p>
                </div>
                <div className="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-[#3bc3ef] border-opacity-60"
                    style={{ borderLeft: '2px solid #3bc3ef' }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                        e.currentTarget.style.border = 'none';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                        e.currentTarget.style.borderLeft = '2px solid #3bc3ef';
                    }}
                >
                    <div className='flex justify-center'>
                        <img src="/media/svg-icon/icon-3.png" className="h-28 max-w-36" alt="" />
                    </div>
                    <h2 className="text-blue-600 mt-3 tracking-widest text-center font-medium title-font mb-1 sm:text-xl">
                        Mentoring
                    </h2>
                    <p className="leading-relaxed text-base mb-4 text-center">
                        Mentoring plays a vital role in digital communication, providing guidance and support to emerging professionals.
                    </p>
                </div>
            </div>
            <div className="text-center mt-5">
                <Link to={'/register'}>
                    <a
                        href="#"
                        className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                        Get Started Today
                    </a>
                </Link>
            </div>
        </div>


    </>
    )
}
