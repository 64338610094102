import { Header } from "./Comp/Header/Header";
import { Routes, Route, } from 'react-router-dom'
import { Board } from './Comp/Nav_Icons/Board/Board'
import { Membership } from './Comp/Nav_Icons/Membership/Membership'
import { Menifesto } from './Comp/Nav_Icons/Menifesto/Menifesto'
import { Events } from './Comp/Nav_Icons/Events/Events'
import { Fruad } from './Comp/Nav_Icons/Fruad/Fruad'
import { Committee } from './Comp/Nav_Icons/Committee/Committee'
import { Footer } from "./Comp/Footer/Footer";
import { Home } from "./Comp/Home/Home";
import { Login } from "./Comp/Nav_Icons/Account/Login";
import { Register } from "./Comp/Nav_Icons/Account/Register";
import { About } from "./Comp/Footer-icons/About";
import { Contact } from "./Comp/Footer-icons/Contact";
import { Disclaimer } from "./Comp/Footer-icons/Disclaimer";
import { Pricing } from "./Comp/Nav_Icons/Membership/Pricing";
import { Members } from "./Comp/Nav_Icons/Members/Members";
import { Switcher } from "./Comp/Switcher/Switcher";
import { BrowserRouter as Router } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import "preline/preline";
import { IStaticMethods } from "preline/preline";
import { Privacy } from "./Comp/Footer-icons/Privacy";
import { Admin } from "./Panel/Admin";


if (typeof window !== 'undefined') {
  window.HSStaticMethods = {
    autoInit: function () {
    }
  };
}

function App() {
  const location = useLocation();

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);


  return (
   
    <>
      {/* <Switcher/> */}
      <Header />
        <Routes>
          <Route path="/" element={< Home />} />
          <Route path="/board" element={< Board />} />
          <Route path="/menifesto" element={< Menifesto />} />
          <Route path="/membership" element={< Membership />} />
          <Route path="/events" element={< Events />} />
          <Route path="fruad" element={<Fruad />} />
          <Route path="/committee" element={< Committee />} />
          <Route path="/login" element={< Login />} />
          <Route path="/register" element={< Register />} />
          <Route path="/about" element={< About />} />
          <Route path="/contact" element={< Contact />} />
          <Route path="/privacy" element={< Privacy />} />
          <Route path="/disclaimer" element={< Disclaimer />} />
          <Route path="/pricing" element={< Pricing />} />
          <Route path="/members" element={< Members />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
