// import React, { useState } from 'react'
// import { B } from '../../Home/B'
// import { Link } from 'react-router-dom'
// export function Board() {
//   return (<>
//     <div className="p-4 h-full bg-[#f5f5f5] mt-10">
//       <div className="p-4  text-center dark:border-gray-700">
//         {/* Banner Component */}
//         <div className="col-start-1 col-end-7 ...">
//           <>
//             <div className="container px-6 py-16 mx-auto">
//               <div className="items-center lg:flex">
//                 <div className="w-full lg:w-1/2">
//                   <div className="lg:max-w-lg">
//                     <h1 className='text-3xl font-semibold text-[#414dd7] dark:text-white lg:text-1xl'>
//                       APNA, <span className="text-[#fe218b]">Union excels</span> in delivering excellence in internet and network services and equipment, fueling your digital ambitions.
//                     </h1>
//                     <p className="mt-3 text-gray-600 dark:text-white">
//                       Uncover the power of Union's rebellious solutions to voice the true potential of your digital world.
//                     </p>
//                     <div className="text-center mt-5">
//                       <Link to={'/register'} >
//                         <a
//                           href="#"
//                           className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
//                         >
//                           Get Started Today
//                         </a>
//                       </Link>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
//                   <img
//                     className="w-full h-full lg:max-w-3xl"
//                     src="https://merakiui.com/images/components/Catalogue-pana.svg"
//                     alt="Catalogue-pana.svg"
//                   />
//                 </div>
//               </div>
//             </div>
//           </>
//         </div>
//       </div>
//     </div>








//     <>
//       <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
//         {/* Main Col */}
//         <div
//           id="profile"
//           className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
//         >
//           <div className="p-4 md:p-12 text-center lg:text-left">
//             {/* Image for mobile view */}
//             <div
//               className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
//               style={{
//                 backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
//               }}
//             />
//             <h1 className="text-3xl font-bold pt-8 lg:pt-0">Your Name</h1>
//             <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25" />
//             <p className="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
//               <svg
//                 className="h-4 fill-current text-green-700 pr-4"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
//               </svg>{" "}
//               What you do
//             </p>
//             <p className="pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
//               <svg
//                 className="h-4 fill-current text-green-700 pr-4"
//                 xmlns="http://www.w3.org/2000/svg"
//                 viewBox="0 0 20 20"
//               >
//                 <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z" />
//               </svg>{" "}
//               Your Location - 25.0000° N, 71.0000° W
//             </p>
//             <p className="pt-8 text-sm">
//               Totally optional short description about yourself, what you do and so
//               on.
//             </p>
//             <div className="pt-12 pb-8">
//               <button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full">
//                 Get In Touch
//               </button>
//             </div>
//             <div className="mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-between">
//               <a className="link" href="#" data-tippy-content="@facebook_handle">
//                 <svg
//                   className="h-6 fill-current text-gray-600 hover:text-green-700"
//                   role="img"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <title>Facebook</title>
//                   <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
//                 </svg>
//               </a>
//               <a className="link" href="#" data-tippy-content="@twitter_handle">
//                 <svg
//                   className="h-6 fill-current text-gray-600 hover:text-green-700"
//                   role="img"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <title>Twitter</title>
//                   <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
//                 </svg>
//               </a>
//               <a className="link" href="#" data-tippy-content="@github_handle">
//                 <svg
//                   className="h-6 fill-current text-gray-600 hover:text-green-700"
//                   role="img"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <title>GitHub</title>
//                   <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
//                 </svg>
//               </a>
//             </div>
//           </div>
//         </div>
//         {/* Image Col */}
//         <div className="w-full lg:w-2/5">
//           {/* Big profile image for side bar (desktop) */}
//           <img
//             src="https://images.unsplash.com/photo-1671726203394-491c8b574a0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"
//             className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
//           />
//         </div>
//       </div>

//     </>









// <>
//   <div className="max-w-4xl flex mt-16 items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0 flex-row-reverse">
//     {/* Main Col */}
//     <div
//       id="profile"
//       className="w-full lg:w-3/5 rounded-lg lg:rounded-r-lg lg:rounded-l-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
//     >
//       <div className="p-4 md:p-12 text-center lg:text-left">
//         {/* Image for mobile view */}
//         <div
//           className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
//           style={{
//             backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
//           }}
//         />
//         <h1 className="text-3xl font-bold pt-8 lg:pt-0">Your Name</h1>
//         <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25" />
//         <p className="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
//           <svg
//             className="h-4 fill-current text-green-700 pr-4"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 20 20"
//           >
//             <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
//           </svg>{" "}
//           What you do
//         </p>
//         <p className="pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
//           <svg
//             className="h-4 fill-current text-green-700 pr-4"
//             xmlns="http://www.w3.org/2000/svg"
//             viewBox="0 0 20 20"
//           >
//             <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z" />
//           </svg>{" "}
//           Your Location - 25.0000° N, 71.0000° W
//         </p>
//         <p className="pt-8 text-sm">
//           Totally optional short description about yourself, what you do and so
//           on.
//         </p>
//         <div className="pt-12 pb-8">
//           <button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full">
//             Get In Touch
//           </button>
//         </div>
//         <div className="mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-between">
//           <a className="link" href="#" data-tippy-content="@facebook_handle">
//             <svg
//               className="h-6 fill-current text-gray-600 hover:text-green-700"
//               role="img"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <title>Facebook</title>
//               <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
//             </svg>
//           </a>
//           <a className="link" href="#" data-tippy-content="@twitter_handle">
//             <svg
//               className="h-6 fill-current text-gray-600 hover:text-green-700"
//               role="img"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <title>Twitter</title>
//               <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
//             </svg>
//           </a>
//           <a className="link" href="#" data-tippy-content="@github_handle">
//             <svg
//               className="h-6 fill-current text-gray-600 hover:text-green-700"
//               role="img"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <title>GitHub</title>
//               <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
//             </svg>
//           </a>
//         </div>
//       </div>
//     </div>
//     {/* Image Col */}
//     <div className="w-full lg:w-2/5">
//       {/* Big profile image for side bar (desktop) */}
//       <img
//         src="https://images.unsplash.com/photo-1671726203394-491c8b574a0a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=988&q=80"
//         className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
//       />
//     </div>
//   </div>

// </>
//   </>)
// }




import { useEffect, useRef } from 'react';
import { Xt } from 'xtendui';
import { Link } from 'react-router-dom';

export function Board() {
  const videoRef = useRef(null);

  useEffect(() => {
    const mountVideoAutoplayLazy = ({ ref }) => {
      const video = ref.querySelector('video[preload="none"]');
      let paused = true;
      let playing = false;
      let timeout;

      const observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          if (entry.intersectionRatio > 0 && paused) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              paused = false;
              playing = video.play();
            }, 50);
          } else if (entry.intersectionRatio === 0 && !paused && playing) {
            paused = true;
            playing.then(() => {
              video.pause();
              playing = false;
            });
            clearTimeout(timeout);
          }
        }
      });

      observer.observe(video);

      return () => {
        clearTimeout(timeout);
        observer.disconnect();
      };
    };

    const unmountVideoAutoplayLazy = mountVideoAutoplayLazy({ ref: videoRef.current });

    return () => {
      unmountVideoAutoplayLazy();
    };
  }, []);

  return (
    <>
      <>
        <div className="relative w-full h-screen">
          <div className="demo--media-video-autoplay-lazy relative w-full h-full" ref={videoRef}>
            <div className="xt-media-container absolute inset-0 bg-gray-200">
              <video className="xt-media w-full h-full object-cover" poster="/placeholder-720.jpg" preload="none" muted playsInline loop>
                <source type="video/mp4" src="/media/video/217134.mp4" />
              </video>
            </div>

            {/* Overlay content aligned to the left */}
            <div className="absolute inset-0 flex flex-col justify-start items-start text-left text-white p-8 mt-20 bg-opacity-50">
              <div className="w-full lg:w-1/2">
                <div className="lg:max-w-lg">
                  <h1 className='text-3xl font-semibold text-[#414dd7] dark:text-white lg:text-1xl md:text-5xl  mb-4 w-fit font-bold from-[#ecedfffa] via-[#D946EF] to-[#c5001d] bg-gradient-to-r bg-clip-text text-transparent'>
                    APNA, Union excels in delivering excellence in internet and network services and equipment, fueling your digital ambitions.
                  </h1>
                  <p className="mt-3 ">
                    Uncover the power of Union's rebellious solutions to voice the true potential of your digital world.
                  </p>
                  <div className="text-center mt-5">
                    <Link to={'/register'} >
                      <a
                        href="#"
                        className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
                      >
                        Get Started Today
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>





      



      <>
        <section className="container mx-auto px-4 py-8">
          <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
            
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                
                <div
                  className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                  style={{
                    backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")',
                  }}
                />
                <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-blue-600">David Forren</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-gray-900 opacity-25" />
                <p className="pt-4 text-base flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-4 fill-current text-blue-600 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                  </svg>{" "}
                  Founder / CEO
                </p>
                <p className="pt-2 text-gray-900 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                  <img
                    src="/media/board-icon/phon.png"
                    alt=""
                    className="h-4 fill-current text-blue-700 pr-4"
                  />
                  +92300-1510222
                </p>
                <p className="pt-8 text-sm">
                  David Forren is the Chief Executive Officer of Jazz, a wholly-owned
                  subsidiary of VEON, since 2016. He has also been a member of the Global
                  Executive Committee of VEON, leading its Emerging Markets Division. His
                  career spans over 25 years, 7 countries and in industries as diverse as
                  telecommunications, automotive, and financial services.
                </p>
                <div className="pt-12 pb-8">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    Get In Touch
                  </button>
                </div>
                <div className="relative h-full">
                  <div className="pl-64 w-4/5 lg:w-full mx-auto flex flex-wrap items-center gap-5 pb-16 lg:pb-0">
                    <a className="link" href="#" data-tippy-content="@facebook_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Facebook</title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@twitter_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Twitter</title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@github_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>GitHub</title>
                        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="w-full lg:w-2/5">
             
              <img
                src="/media/ceo/ceo-2.jpg"
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>
        </section>
      </>








      <>
        <div className="container mx-auto p-4 rounded-md mt-16 ">
          <div className="max-w-4xl flex mt-16 items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0 flex-row-reverse">
            {/* Main Col */}
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-r-lg lg:rounded-l-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                {/* Image for mobile view */}
                <div
                  className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                  style={{
                    backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
                  }}
                />
                <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-blue-600">Tom Andreas</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-gray-900 opacity-25" />
                <p className="pt-4 text-base from-neutral-600 flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-4 fill-current text-blue-600 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                  </svg>{" "}
                  Coo Founder
                </p>

                <p className="pt-2 text-gray-900 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                  <img src="/media/board-icon/phon.png" alt="" className="h-4  fill-current text-blue-700 pr-4" />

                  +92300-1510222
                </p>
                <p className="pt-8 text-gray-900 text-sm">
                  Tom  Andreas is CEO and co-founder of Nayatel, Pakistan’s first fiber to the home (FTTH) network in major cities to provide broadband, HD TV and phone services to business and home users. Earlier, Wahaj co-founded Micronet Broadband, the first one to launch broadband DSL
                </p>
                <div className="pt-12 pb-8">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    Get In Touch
                  </button>
                </div>

                <div className="relative h-full">
                  <div className=" pl-64 w-4/5 lg:w-full mx-auto flex flex-wrap items-center gap-5 pb-16 lg:pb-0">
                    <a className="link" href="#" data-tippy-content="@facebook_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Facebook</title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@twitter_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Twitter</title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@github_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>GitHub</title>
                        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Col */}
            <div className="w-full lg:w-2/5">
              {/* Big profile image for side bar (desktop) */}
              <img
                // src="https://unsplash.com/photosman-wearing-black-notched-lapel-suit-jacket-in-focus-photography-WMD64tMfc4k"
                src='/Media/ceo/ceo-1.jpg'
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>
        </div>
      </>



      <>
        <div className="container mx-auto p-4 rounded-md mt-16 bg-[#f5f5f5]">
          <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
            {/* Main Col */}
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                {/* Image for mobile view */}
                <div
                  className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                  style={{
                    backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
                  }}
                />
                <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-blue-600">David Forren</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-gray-900 opacity-25" />
                <p className="pt-4 text-base  flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-4 fill-current text-blue-600 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                  </svg>{" "}
                  Founder / CEO
                </p>
                <p className="pt-2 text-gray-900 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                  <img src="/media/board-icon/phon.png" alt="" className="h-4  fill-current text-blue-700 pr-4" />

                  +92300-1510222
                </p>
                <p className="pt-8 text-sm">
                  David Forren is the Chief Executive Officer of Jazz, a wholly-owned subsidiary of VEON, since 2016. He has also been a member of the Global Executive Committee of VEON, leading its Emerging Markets Division. His career spans over 25 years, 7 countries and in industries as diverse as telecommunications, automotive, and financial services.
                </p>
                <div className="pt-12 pb-8">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    Get In Touch
                  </button>
                </div>

                <div className="relative h-full">
                  <div className=" pl-64 w-4/5 lg:w-full mx-auto flex flex-wrap items-center gap-5 pb-16 lg:pb-0">
                    <a className="link" href="#" data-tippy-content="@facebook_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Facebook</title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@twitter_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Twitter</title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@github_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>GitHub</title>
                        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Col */}
            <div className="w-full lg:w-2/5">
              {/* Big profile image for side bar (desktop) */}
              <img
                src="/media/ceo/ceo-3.jpg"
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>

        </div>
      </>



      <>
        <div className="container mx-auto p-4 rounded-md mt-16 ">
          <div className="max-w-4xl flex mt-16 items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0 flex-row-reverse">
            {/* Main Col */}
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-r-lg lg:rounded-l-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                {/* Image for mobile view */}
                <div
                  className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                  style={{
                    backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
                  }}
                />
                <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-blue-600">Tom Andreas</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-gray-900 opacity-25" />
                <p className="pt-4 text-base from-neutral-600 flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-4 fill-current text-blue-600 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                  </svg>{" "}
                  Coo Founder
                </p>

                <p className="pt-2 text-gray-900 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                  <img src="/media/board-icon/phon.png" alt="" className="h-4  fill-current text-blue-700 pr-4" />

                  +92300-1510222
                </p>
                <p className="pt-8 text-gray-900 text-sm">
                  Tom  Andreas is CEO and co-founder of Nayatel, Pakistan’s first fiber to the home (FTTH) network in major cities to provide broadband, HD TV and phone services to business and home users. Earlier, Wahaj co-founded Micronet Broadband, the first one to launch broadband DSL
                </p>
                <div className="pt-12 pb-8">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    Get In Touch
                  </button>
                </div>

                <div className="relative h-full">
                  <div className=" pl-64 w-4/5 lg:w-full mx-auto flex flex-wrap items-center gap-5 pb-16 lg:pb-0">
                    <a className="link" href="#" data-tippy-content="@facebook_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Facebook</title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@twitter_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Twitter</title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@github_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>GitHub</title>
                        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Col */}
            <div className="w-full lg:w-2/5">
              {/* Big profile image for side bar (desktop) */}
              <img
                src="https://images.unsplash.com/photo-1546572797-e8c933a75a1f?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>
        </div>
      </>





      <>
        <div className="container mx-auto p-4 rounded-md mt-16 bg-[#f5f5f5]">
          <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
            {/* Main Col */}
            <div
              id="profile"
              className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
            >
              <div className="p-4 md:p-12 text-center lg:text-left">
                {/* Image for mobile view */}
                <div
                  className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                  style={{
                    backgroundImage: 'url("https://source.unsplash.com/MP0IUfwrn0A")'
                  }}
                />
                <h1 className="text-3xl font-bold pt-8 lg:pt-0 text-blue-600">David Forren</h1>
                <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-gray-900 opacity-25" />
                <p className="pt-4 text-base  flex items-center justify-center lg:justify-start">
                  <svg
                    className="h-4 fill-current text-blue-600 pr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
                  </svg>{" "}
                  Founder / CEO
                </p>
                <p className="pt-2 text-gray-900 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                  <img src="/media/board-icon/phon.png" alt="" className="h-4  fill-current text-blue-700 pr-4" />

                  +92300-1510222
                </p>
                <p className="pt-8 text-sm">
                  David Forren is the Chief Executive Officer of Jazz, a wholly-owned subsidiary of VEON, since 2016. He has also been a member of the Global Executive Committee of VEON, leading its Emerging Markets Division. His career spans over 25 years, 7 countries and in industries as diverse as telecommunications, automotive, and financial services.
                </p>
                <div className="pt-12 pb-8">
                  <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                    Get In Touch
                  </button>
                </div>

                <div className="relative h-full">
                  <div className=" pl-64 w-4/5 lg:w-full mx-auto flex flex-wrap items-center gap-5 pb-16 lg:pb-0">
                    <a className="link" href="#" data-tippy-content="@facebook_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Facebook</title>
                        <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@twitter_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Twitter</title>
                        <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                      </svg>
                    </a>
                    <a className="link" href="#" data-tippy-content="@github_handle">
                      <svg
                        className="h-6 fill-current text-gray-600 hover:text-green-700"
                        role="img"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>GitHub</title>
                        <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.725-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.09-.744.083-.729.083-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.807 1.304 3.492.997.108-.776.418-1.305.76-1.605-2.665-.305-5.466-1.332-5.466-5.931 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.527.117-3.176 0 0 1.008-.322 3.301 1.23a11.502 11.502 0 0 1 3.004-.404c1.02.005 2.047.137 3.003.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.649.242 2.873.119 3.176.77.84 1.234 1.911 1.234 3.221 0 4.61-2.805 5.623-5.476 5.921.43.371.823 1.102.823 2.222 0 1.604-.015 2.896-.015 3.293 0 .319.216.694.824.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Col */}
            <div className="w-full lg:w-2/5">
              {/* Big profile image for side bar (desktop) */}
              <img
                src="https://images.unsplash.com/photo-1601489664830-d9c5fd91fb00?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
              />
            </div>
          </div>

        </div>
      </>


{/*  */}


    </>
  );
};

// export default VideoAutoplayLazy;
