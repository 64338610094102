import React from 'react'
import { Link } from 'react-router-dom';

export function Committee() {
  return (<>


    {/* <section className="relative py-28 bg-blue-600">
      <div className="relative z-10 max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h3 className="text-blue-100 font-semibold pb-6">What people are saying</h3>
          <figure>
            <blockquote>
              <p className="text-white text-xl font-semibold sm:text-2xl">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et est hendrerit, porta nunc vitae, gravida justo. Nunc fermentum magna lorem, euismod volutpat arcu volutpat et.“
              </p>
            </blockquote>
            <div className="mt-6">
              <img src="https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg" className="w-16 h-16 mx-auto rounded-full" />
              <div className="mt-3">
                <span className="block text-white font-semibold">Martin escobar</span>
                <span className="block text-blue-100 text-sm mt-0.5">Founder of meta</span>
              </div>
            </div>
          </figure>
        </div>
      </div>
      <div className="absolute top-0 w-full h-full" style={{ background: "linear-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)" }}></div>
    </section> */}



    <>
      {/* <div className="relative top-20 overflow-hidden bg-[#f5f5f5]">
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10">
          <div className="max-w-2xl text-center mx-auto">
            <h1 className="block text-3xl font-bold text-blue-600 sm:text-4xl md:text-5xl">
              Our Committee Board
            </h1>
            <p className="mt-3 text-lg  ">
              At Our Board, we unite ISPs, network service providers, and operators to create a secure and thriving environment. Join us in fostering connectivity and innovation across Pakistan.
            </p>
          </div>
          <div className="mt-10 relative max-w-5xl mx-auto">
            <div className="w-full object-cover h-96 sm:h-[480px] bg-[url('https://images.unsplash.com/photo-1606868306217-dbf5046868d2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1981&q=80')] bg-no-repeat bg-center bg-cover rounded-xl" />
            <div className="absolute inset-0 size-full">

            </div>
            <div className="absolute bottom-12 -start-20 -z-[1] size-48 bg-gradient-to-b from-orange-500  to-white p-px rounded-lg">
              <div className=" size-48 rounded-lg" />
            </div>
            <div className="absolute -top-12 -end-20 -z-[1] size-48 bg-gradient-to-t from-blue-600 to-cyan-400 p-px rounded-full">
              <div className=" size-48 rounded-full" />
            </div>
          </div>
        </div>
      </div> */}


      <div
        className="flex h-screen justify-center items-center bg-cover bg-center dark:bg-slate-800"
        style={{ backgroundImage: "url('https://img.freepik.com/free-vector/worldwide-connection-gray-background-illustration-vector_53876-76825.jpg?t=st=1722834298~exp=1722837898~hmac=d981116ce5d5632e49733a13a39d303f0e6e90dad6f575994ba80d71f953ed19&w=826')" }} // Replace this URL with your image URL
      >
        <div className="mx-auto mt-10 flex justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8  bg-opacity-70 p-8 rounded-lg ">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-200 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">
                {/* <span className="mb-1 block">Create amazing</span> */}
                <span className="bg-gradient-to-r from-indigo-400 to-pink-600 bg-clip-text text-transparent">
                  Our Committee Board
                </span>
              </span>
            </h1>
            <p className="mx-auto mt-3 max-w-xl text-lg text-gray-800 dark:text-slate-800 sm:mt-5 md:mt-5">
              At Our Board, we unite ISPs, network service providers, and operators to create a secure and thriving environment. Join us in fostering connectivity and innovation across Pakistan.
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
              <div className="rounded-md shadow">
                <a
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-8 py-3 text-base font-medium text-white hover:bg-blue-700 md:py-4 md:px-10 md:text-lg"
                  href="#"
                >
                  Get started for free 🚀
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>











    <>
      {/* committee section  */}
      <section className=" bg-[#f5f5f5]  mt-40">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="mx-auto max-w-lg text-center ">
            <h2 className="text-3xl font-bold sm:text-4xl text-blue-600">
              Meet Our Committee Members
            </h2>

            {/* <p classname="mt-4 text-lg  text-gray-800">
            Our committee members at Our Board are a diverse group of experienced professionals dedicated to advancing the interests of ISPs, network service providers, and operators. Each member brings unique expertise and a commitment to fostering a secure and thriving environment for our community. They collaborate on strategic initiatives, advocate for fair policies, and ensure the protection and growth of network services. Through their collective efforts, our committee members drive innovation, inclusivity, and progress, ensuring that we address industry challenges and seize opportunities. Their leadership and dedication are the cornerstone of our mission to enhance connectivity and collaboration.
          </p> */}


            <div className="w-full max-w-8xl mx-auto">
              <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
                Our committee members at Our Board are a diverse group of experienced professionals dedicated to advancing the interests of ISPs, network service providers, and operators.
              </p>
            </div>




          </div>
          <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 ">
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">IT Comittee</h2>

              <p className="mt-1 text-sm text-gray-800">
                Our committee is dedicated to driving impactful initiatives and fostering collaboration. We focus on bringing together experts and stakeholders to develop innovative solutions and address key challenges.
              </p>

            </Link>

            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Events Committee</h2>

              <p className="mt-1 text-sm text-gray-800">
                The Events Committee is responsible for organizing and executing a wide range of events, from small gatherings to large-scale conferences.
              </p>

            </Link>
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Legal Committee</h2>
              <p className="mt-1 text-sm text-gray-800">
                <p className="mt-1 text-sm text-gray-800">
                  The Legal Committee is tasked with overseeing and ensuring compliance with legal and regulatory requirements.
                </p>

              </p>
            </Link>
            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">Membership Committee</h2>

              <p className="mt-1 text-sm text-gray-800">
                The Membership Committee is dedicated to growing and maintaining a vibrant and engaged membership base.
              </p>

            </Link>

            <Link to={'/members'}
              className="block rounded-xl border  p-8  " style={{

                border: '1px solid #3bc3ef'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 30px #3bc3ef';
                e.currentTarget.style.border = 'none';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.boxShadow = '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                e.currentTarget.style.border = '1px solid #3bc3ef';
              }}
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 text-blue-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                <path
                  strokelinecap="round"
                  strokelinejoin="round"
                  strokewidth={2}
                  d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                />
              </svg>
              <h2 className="mt-4 text-xl font-bold text-blue-600">R&D Committee</h2>
              <p className="mt-1 text-sm text-gray-800">
                The R&D Committee is focused on driving innovation and advancing research within our organization.
              </p>

            </Link>

          </div>



          <div className="mt-12 text-center">
            <Link to={'/register'} >
              <a
                href="#"
                className="inline-block rounded bg-pink-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-pink-700 focus:outline-none focus:ring focus:ring-yellow-400"
              >
                Get Started Today
              </a>
            </Link>
          </div>
        </div>
      </section>
      {/* </div> */}

    </>
  </>
  )
}
